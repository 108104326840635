import FileUploadsController from '../file_uploads_controller';

function log(message) {
  console.log(`${new Date().getTime()}: ${message}`);
}

export default class extends FileUploadsController {
  static targets = super.targets.concat(['customLinkDiv', 'customLinkNameInput', 'linkNameInput', 'warnings'])

  attach(id) {
    const signedBlobId = this.element.querySelector('input[name$="[upload_attachments]"][type="hidden"]');
    let linkName;
    if (this.linkNameInputTarget.value === 'Other' || this.linkNameInputTarget.value === '') {
      linkName = this.customLinkNameInputTarget.value.trim();
    } else {
      linkName = this.linkNameInputTarget.value.trim();
    }
    const uploadData = { upload: { signedBlobId: signedBlobId.value, linkName } };
    let attachUrl = this.element.getAttribute('data-attach-url');
    log(`attachUrl: ${attachUrl}`);
    if (typeof (attachUrl) === 'undefined' || attachUrl == null || attachUrl === '') { attachUrl = 'attach'; }

    fetch(attachUrl, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(uploadData)
    }).then((response) => {
      log(`fetch response (${id}, ${response.ok})`);
      if (!response.ok) {
        alert(`Error: (${response.status}, ${response.statusText}, ${response.url})`);
        throw response;
      }
      return response.json();
    }).then(() => {
      log(`fetch success (${id})`);
      window.location = window.location;
    }).catch((error) => {
      log(`fetch error (${id})`, error);
      this.uploadedFileCount -= 1;
      this.progressContainerTarget.children[0].style.width = `${Math.round(this.uploadedFileCount / this.selectedFileCount * 100)}%`;
      this.progressContainerTarget.children[1].innerHTML = `${this.uploadedFileCount}/${this.selectedFileCount}`;
    });
  }

  toggleCustomField() {
    if (this.linkNameInputTarget.value === 'Other') {
      this.customLinkDivTarget.hidden = false;
      this.customLinkNameInputTarget.focus();
    } else {
      this.customLinkDivTarget.hidden = true;
      this.customLinkNameInputTarget.value = '';
    }
  }

  checkForExistingLinkNames() {
    const eventLinkNames = JSON.parse(this.warningsTarget.getAttribute('data-event-link-names'));
    let warningName;
    if (eventLinkNames.includes(this.linkNameInputTarget.value.toLowerCase())) {
      warningName = this.linkNameInputTarget.value;
    } else if (eventLinkNames.includes(this.customLinkNameInputTarget.value.toLowerCase())) {
      warningName = this.customLinkNameInputTarget.value.toLowerCase().split(' ');
      warningName = warningName.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ').trim();
    }
    this.warningsTarget.replaceChildren();
    const warning = document.createElement('div');
    warning.classList.add('alert', 'alert-warning', 'mb-4');
    if (this.customLinkNameInputTarget.value.toLowerCase() === 'catalog' || this.customLinkNameInputTarget.value.toLowerCase().includes('consignment')
        || this.customLinkNameInputTarget.value.toLowerCase() === 'video catalog') {
      warning.innerHTML = '<i class="fa fa-info-circle"></i> Use Catalog or Consignment Document uploader';
      this.warningsTarget.appendChild(warning);
    } else if (warningName) {
      warning.innerHTML = `<i class="fa fa-info-circle"></i> Existing '${warningName}' will be replaced`;
      this.warningsTarget.appendChild(warning);
    }
  }

  end({ detail: { id } }) {
    super.end({ detail: { id } });
    document.querySelector('div[data-target="admin--remote-loading.content"]').hidden = true;
    document.querySelector('div[data-target="admin--remote-loading.loadingIcon"]').hidden = false;
  }

  toggleSubmitButton() {
    if (this.customLinkNameInputTarget.value.toLowerCase() === 'catalog' || this.customLinkNameInputTarget.value.toLowerCase().includes('consignment')
        || this.customLinkNameInputTarget.value.toLowerCase() === 'video catalog') {
      this.addFilesButtonTarget.classList.add('d-none');
    } else if (((this.linkNameInputTarget.value === 'Other') && (this.customLinkNameInputTarget.value.replaceAll(' ', '') === ''))
                 || this.linkNameInputTarget.value === '') {
      this.addFilesButtonTarget.classList.add('d-none');
    } else {
      this.addFilesButtonTarget.classList.remove('d-none');
    }
  }
}
