import { Controller } from 'stimulus';
import moment from 'moment-timezone';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

const { timeZone } = Window;

class TimeZoneHelper {
  constructor(dateTime) {
    this.baseTime = moment.tz(dateTime, timeZone);
  }

  timeZone(tz) {
    return tz ? this.baseTime.clone().tz(tz).format('z') : this.baseTime.clone().format('z');
  }

  time(tz) {
    const format = window.dataEnableSeconds ? 'LTS' : 'h:mm A';
    return this.baseTime.clone().tz(tz).format(format);
  }
}

export default class extends Controller {
  static targets = ['centralLabel', 'centralTime', 'easternLabel', 'easternTime', 'hour', 'input',
    'meridiem', 'mountainLabel', 'mountainTime', 'pacificLabel', 'pacificTime', 'timeZoneLabelsContainer'];

  connect() {
    const controller = this;
    window.dataEnableSeconds = controller.data.get('seconds') === 'true';
    this.dataEnableTime = controller.data.get('enable-time') !== 'false';
    flatpickr(this.inputTarget, {
      allowInput: true,
      allowInvalidPreload: true,
      altInput: true,
      defaultDate: this.data.get('time'),
      disableMobile: 'true',
      enableSeconds: window.dataEnableSeconds,
      enableTime: this.dataEnableTime,
      minuteIncrement: 1,
      onClose() { controller.updateTime(); },
      onReady() { controller.updateTime(); },
      onValueUpdate() { controller.updateTime(); }
    });
  }

  updateTime() {
    if (this.hasTimeZoneLabelsContainerTarget) {
      if (this.inputTarget.value) {
        this.tzh = new TimeZoneHelper(this.inputTarget.value);
        this.format = window.dataEnableSeconds ? 'MMMM D, YYYY h:mm:ss A' : 'LLL';
        this.labelTime = moment(this.inputTarget.value).format(this.format);

        this.centralLabelTarget.innerHTML = this.tzh.timeZone('America/Chicago');
        this.centralTimeTarget.innerHTML = this.tzh.time('America/Chicago');

        this.easternLabelTarget.innerHTML = this.tzh.timeZone('America/New_York');
        this.easternTimeTarget.innerHTML = this.tzh.time('America/New_York');

        this.inputTarget.nextSibling.value = `${this.labelTime} (${this.tzh.timeZone()})`;

        this.pacificLabelTarget.innerHTML = this.tzh.timeZone('America/Los_Angeles');
        this.pacificTimeTarget.innerHTML = this.tzh.time('America/Los_Angeles');

        this.mountainLabelTarget.innerHTML = this.tzh.timeZone('America/Denver');
        this.mountainTimeTarget.innerHTML = this.tzh.time('America/Denver');

        this.timeZoneLabelsContainerTarget.hidden = false;
      } else {
        this.timeZoneLabelsContainerTarget.hidden = true;
      }
    }
  }
}
