import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'content', 'fileNames', 'form', 'input', 'loadingIcon', 'string', 'submitButton'];

  autoStandardize(event) {
    event.preventDefault();
    this.inputTargets.forEach((input) => {
      this.value = input.value.split('.');
      this.parenthesesContent = input.value.match(/\(.*\)/g);
      this.fileName = this.value[0].replace(this.parenthesesContent, '').trim().replace(/lot[-_\s]*/g, '').replace(/\s+/g, '-');
      this.extension = this.value[this.value.length - 1].replace(/\s+/g, '');
      if (this.parenthesesContent) {
        input.value = `${this.fileName} ${this.parenthesesContent[0].replace(/\s+/g, '')}.${this.extension}`;
      } else {
        input.value = `${this.fileName}.${this.extension}`;
      }
    });
    this.checkDuplicateFileName();
  }

  checkDuplicateFileName() {
    if (!this.hasFileNamesTarget) { return; }

    this.existingFileNames = JSON.parse(this.fileNamesTarget.getAttribute('data-value'));
    this.newFileNames = [];
    this.selectedFileNames = [];

    this.inputTargets.forEach((inputTarget) => {
      this.newFileNames.push(inputTarget.value);
      this.selectedFileNames.push(inputTarget.getAttribute('data-value'));
    });

    this.selectedFileNames.forEach((selectedFileName) => {
      this.existingFileNames = this.existingFileNames.filter(existingFileName => existingFileName !== selectedFileName);
    });

    this.existingFileNames = this.existingFileNames.filter(existingFileName => this.newFileNames.includes(existingFileName));
    this.newFileNames = this.newFileNames.filter((newFileName, index) => this.newFileNames.indexOf(newFileName) !== index);
    this.existingFileNames = this.existingFileNames.concat(this.newFileNames);

    this.inputTargets.forEach((inputTarget) => {
      this.errorMessages = [];
      if (this.existingFileNames.includes(inputTarget.value)) {
        this.errorMessages.push('File name already exists');
      }

      if (!inputTarget.value.split('.')[0].replace(/\s?\(.*\)/g, '').match(/^[a-zA-Z0-9_-]+$/)) {
        this.errorMessages.push('Invalid characters for lot assignment');
      }

      this.errorMessage = inputTarget.parentNode.querySelector('.invalid-feedback');

      if (this.errorMessages.length > 0) {
        if (this.errorMessage) {
          this.errorMessage.innerText = this.errorMessages.join('\n');
        } else {
          inputTarget.classList.add('is-invalid');
          this.errorMessage = document.createElement('div');
          this.errorMessage.classList.add('invalid-feedback');
          this.errorMessage.innerText = this.errorMessages.join('\n');
          inputTarget.parentNode.appendChild(this.errorMessage);
        }
      } else {
        inputTarget.classList.remove('is-invalid');
        if (this.errorMessage) {
          this.errorMessage.remove();
        }
      }

      inputTarget.closest('tr').querySelector('.changes').hidden = inputTarget.getAttribute('data-value') === inputTarget.value;
    });

    if (document.querySelectorAll('.is-invalid').length !== 0) {
      this.submitButtonTarget.disabled = true;
    } else {
      this.submitButtonTarget.disabled = this.element.querySelectorAll('.changes:not([hidden])').length === 0;
    }
  }

  removeString() {
    this.inputTargets.forEach((input) => {
      if (input.getAttribute('data-value').includes('.')) {
        this.fileNameParts = input.value.split('.');
        this.fileExtension = this.fileNameParts.pop();
        this.fileName = this.fileNameParts.pop();
        this.fileName = this.fileName.replaceAll(this.stringTarget.value.trim(), '').trim().concat('.', this.fileExtension).replace(/\s+/g, ' ');
      } else {
        this.fileName = input.getAttribute('data-value').replaceAll(this.stringTarget.value, '').trim();
      }

      input.value = this.fileName;
    });

    this.checkDuplicateFileName();
  }

  resetValues(event) {
    event.preventDefault();
    this.inputTargets.forEach((input) => {
      input.classList.remove('is-invalid');
      this.errorMessages = input.parentNode.querySelectorAll('.invalid-feedback');
      this.errorMessages.forEach((errorMessage) => { errorMessage.remove(); });
      this.message = input.parentNode.querySelector('.text-success');
      this.errorMessages = input.parentNode.querySelectorAll('.text-success');
      this.errorMessages.forEach((errorMessage) => { errorMessage.remove(); });
      input.value = input.getAttribute('data-value');
    });
    this.checkDuplicateFileName();
    this.submitButtonTarget.disabled = true;
  }

  submitForm(event) {
    event.preventDefault();
    this.inputTargets.forEach((input) => {
      this.values = input.value.split('.');
      this.extension = this.values[this.values.length - 1].replace(/\s+/g, '');
      this.value = [this.values[0], this.extension].join('.').trim();
      input.value = this.value;
    });
    this.element.querySelectorAll('.changes[hidden]').forEach((input) => { input.closest('tr').remove(); });
    this.formTarget.submit();
    this.submitButtonTarget.disabled = true;
    this.formTarget.hidden = true;
    this.loadingIconTarget.hidden = false;
  }
}
